import React, { useState, useEffect } from 'react';
import { authenticatedFetch } from '../api';
import { MessageSquare, CheckCircle } from 'lucide-react';

const Feedback = () => {
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    document.title = "Tagasiside | Volton"; // Muudab lehe title
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSubmitted(false);

    try {
      const response = await authenticatedFetch('/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      if (response.ok) {
        setSubmitted(true);
        setMessage('');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Tagasiside esitamisel tekkis viga');
      }
    } catch (error) {
      setError('Tekkis viga. Palun proovige uuesti.');
    }
  };

  return (
    <div className="bg-gray-900 background-grid min-h-screen flex items-center justify-center">
      <div className="bg-gray-800 p-8 pb-6 rounded-2xl shadow-lg border border-gray-700 max-w-md w-full flex flex-col">
      <h2 className="text-lg font-semibold mb-4 text-white flex items-center">
          <MessageSquare size={24} className="mr-3 text-brand" />
          Tagasiside
        </h2>
        {submitted ? (
          <p className="text-green-400 flex items-center">
            <CheckCircle className="mr-2" size={20} />
            Täname teid tagasiside eest!
          </p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="message" className="block text-sm text-gray-400 mb-4">
                Jagage meiega mõtteid, ettepanekuid või teavitage rakendusest leitud probleemidest.
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-brand focus:ring focus:ring-brand focus:ring-opacity-50"
                rows="4"
                required
              />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div className="flex justify-end">
              <button type="submit" className="w-1/4 bg-brand text-gray-900 py-2 text-sm px-3 rounded-md hover:bg-brand/80 transition-colors duration-200">
                Saada
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Feedback;