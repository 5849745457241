import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Power, ChevronUp, ChevronDown, Pencil, Waves, Hourglass, Thermometer, Trash2, Unplug, CalendarRange, Snowflake, Info, CalendarClock, AlertTriangle, Clock, X, Zap, Coins, Check, Lock, Save, FlipVertical, Plus, Loader, Wifi,WifiHigh, WifiLow, WifiZero, WifiOff, Globe, Network } from 'lucide-react';
import { authenticatedFetch } from '../api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { parseISO, endOfDay, addDays, formatDistanceToNow, format, isToday, isTomorrow, differenceInSeconds } from 'date-fns';
import { et } from 'date-fns/locale';
const isDemoMode = process.env.REACT_APP_DEMO === 'true';

const DeviceStatus = ({ device, onDeviceUpdate, onDeviceDelete }) => {
  const [isOn, setIsOn] = useState(device.status);
  const [isOnline, setIsOnline] = useState(device.online);
  const [powerConsumption, setPowerConsumption] = useState(device.power_consumption || 0);
  const [hourlyCost, setHourlyCost] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [deviceName, setDeviceName] = useState(device.device_name);
  const [isEditingName, setIsEditingName] = useState(false);
  const [tempDeviceName, setTempDeviceName] = useState(device.device_name);
  const [automationMode, setAutomationMode] = useState(device.automation_mode || 'piirhind');
  const [isAutomationEnabled, setIsAutomationEnabled] = useState(device.automation_enabled);
  const [showDisableAutomationModal, setShowDisableAutomationModal] = useState(false);
  const [selectedDisableOption, setSelectedDisableOption] = useState('until_reenabled');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [toggleError, setToggleError] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [countdown, setCountdown] = useState('');
  const [nextAction, setNextAction] = useState(null);
  const [scheduleData, setScheduleData] = useState(device.schedule);
  const [lastOnline, setLastOnline] = useState(device.last_online);
  const [settings, setSettings] = useState(device.settings || {});
  const [isUpdating, setIsUpdating] = useState(false);
  const [nextScheduledAction, setNextScheduledAction] = useState(null);
  const scheduleIntervalRef = useRef(null);
  const statusIntervalRef = useRef(null);
  const initialFetchDoneRef = useRef(false);
  const [isAutomationToggling, setIsAutomationToggling] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const calculateNextAction = useCallback(() => {
    if (isAutomationEnabled && scheduleData.length > 0) {
      const now = new Date();
      const nextAction = scheduleData.find(action => parseISO(action.timestamp) > now);
      if (nextAction) {
        setNextAction(nextAction.state ? 'sisse' : 'välja');
        setNextScheduledAction(nextAction);
      } else {
        setNextAction(null);
        setNextScheduledAction(null);
      }
    } else {
      setNextAction(null);
      setNextScheduledAction(null);
    }
  }, [isAutomationEnabled, scheduleData]);

  const fetchDeviceStatus = useCallback(async () => {
    try {
      const response = await authenticatedFetch(`/devices/${device.id}/status`);
      if (!response.ok) throw new Error('Seadme oleku pärimine ebaõnnestus');
      const statusData = await response.json();
      setIsOn(statusData.status);
      setIsOnline(statusData.online);
      setPowerConsumption(statusData.power_consumption);
      setLastOnline(statusData.last_online);
      setIsAutomationEnabled(statusData.automation_enabled);

      const hourlyConsumptionKWh = statusData.power_consumption / 1000;
      const hourlyPriceEuroCents = statusData.current_price;
      const calculatedHourlyCost = (hourlyConsumptionKWh * hourlyPriceEuroCents);

      setHourlyCost(calculatedHourlyCost);
    } catch (error) {
      console.error('Error fetching device status:', error);
    }
  }, [device.id]);

  const fetchScheduleData = useCallback(async () => {
    try {
      const startDate = new Date(Date.now());
      const endDate = endOfDay(addDays(new Date(), 1));

      const response = await authenticatedFetch(`/devices/${device.id}/schedule?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`);
      if (!response.ok) throw new Error('');
      const data = await response.json();
      setScheduleData(data);
      calculateNextAction();
    } catch (error) {
      console.error('Error fetching schedule data:', error);
    }
  }, [device.id, calculateNextAction]);

  useEffect(() => {
    if (!initialFetchDoneRef.current) {
      fetchDeviceStatus();
      fetchScheduleData();
      initialFetchDoneRef.current = true;
    }

    statusIntervalRef.current = setInterval(fetchDeviceStatus, 5000);
    scheduleIntervalRef.current = setInterval(fetchScheduleData, 60000);

    return () => {
      if (statusIntervalRef.current) clearInterval(statusIntervalRef.current);
      if (scheduleIntervalRef.current) clearInterval(scheduleIntervalRef.current);
    };
  }, [fetchDeviceStatus, fetchScheduleData]);

  useEffect(() => {
    let animationFrameId;

    const updateCountdown = () => {
      if (nextScheduledAction) {
        const now = new Date();
        const actionTime = parseISO(nextScheduledAction.timestamp);
        const diffInSeconds = differenceInSeconds(actionTime, now);

        if (diffInSeconds > 0) {
          const hours = Math.floor(diffInSeconds / 3600);
          const minutes = Math.floor((diffInSeconds % 3600) / 60);
          const seconds = diffInSeconds % 60;
          const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          setCountdown(formattedTime);
        } else {
          setCountdown('');
          calculateNextAction();
        }
      } else {
        setCountdown('');
      }

      animationFrameId = requestAnimationFrame(updateCountdown);
    };

    updateCountdown();

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [nextScheduledAction, calculateNextAction]);
  const CustomWifiIcon = ({ signal, size = 20 }) => {
    const getWifiIcon = (signal) => {
      if (signal === undefined) return WifiOff;
      if (signal >= -50) return Wifi;
      if (signal >= -60) return WifiHigh;
      if (signal >= -70) return WifiLow;
      return WifiZero;
    };
  
    const WifiIcon = getWifiIcon(signal);
  
    return (
      <div className="relative inline-block">
        <Wifi size={size} className="text-gray-500" />
        <div className="absolute inset-0">
          <WifiIcon 
            size={size} 
            className={signal === undefined ? "text-gray-400" : "text-gray-400"}
          />
        </div>
      </div>
    );
  };
  
  const renderWifiIcon = (signal) => {
    return <CustomWifiIcon signal={signal} />;
  };
  const handleSave = async () => {
    setIsUpdating(true);
    try {
      const updatedDevice = {
        device_id: device.id,
        device_name: deviceName,
        automation_mode: automationMode,
        automation_enabled: isAutomationEnabled || !device.automation_enabled,
        settings: settings,
      };

      const response = await authenticatedFetch(`/update_device_profile`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedDevice),
      });

      if (!response.ok) throw new Error('Seadme profiili uuendamine ebaõnnestus');

      const data = await response.json();

      setIsAutomationEnabled(updatedDevice.automation_enabled);
      setScheduleData(data.schedule || []);
      const fullUpdatedDevice = {
        ...device,
        ...updatedDevice,
        schedule: data.schedule,
        settings: data.settings,
        status: isOn,
        online: isOnline,
        power_consumption: powerConsumption,
        last_online: lastOnline,
      };

      onDeviceUpdate(fullUpdatedDevice);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error updating device profile:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDiscard = () => {
    setAutomationMode(device.automation_mode || 'piirhind');
    setSettings(device.settings || {});
    setHasUnsavedChanges(false);
  };
  const handleAutomationToggle = async () => {
    setIsAutomationToggling(true);
    if (isAutomationEnabled) {
      setShowDisableAutomationModal(true);
      setIsAutomationToggling(false);
    } else {
      await updateAutomationState(true);
    }
  };
  const updateAutomationState = async (newState) => {
    setIsAutomationToggling(true);
    try {
      const response = await authenticatedFetch(`/update_device_profile`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ device_id: device.id, automation_enabled: newState }),
      });  
      if (!response.ok) throw new Error('Automaatrežiimi uuendamine ebaõnnestus');

      const updatedDevice = await response.json();
      setIsAutomationEnabled(updatedDevice.automation_enabled);
      onDeviceUpdate({ ...device, ...updatedDevice });
    } catch (error) {
      console.error('Error updating automation state:', error);
      // Optionally, show an error message to the user
    } finally {
      setIsAutomationToggling(false);
    }
  };
  const handleNameChange = async (newName) => {
    try {
      const response = await authenticatedFetch(`/update_device_profile`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ device_id: device.id, device_name: newName }),
      });

      if (!response.ok) throw new Error('Seadme nime uuendamine ebaõnnestus');

      setDeviceName(newName);
      onDeviceUpdate({ ...device, device_name: newName });
    } catch (error) {
      console.error('Error updating device name:', error);
    }
  };
  const toggleDevice = async () => {
    if (isAutomationEnabled) {
      setShowDisableAutomationModal(true);
      return;
    }

    if (isToggling) return;
    
    setIsToggling(true);
    setToggleError(false);
    
    try {
      const response = await authenticatedFetch(`/toggle_device`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ device_id: device.id, state: !isOn }),
      });

      if (!response.ok) throw new Error('Seadme lülitamine ebaõnnestus');

      let attempts = 0;
      const maxAttempts = 5;
      const pollInterval = setInterval(async () => {
        attempts++;
        const statusResponse = await authenticatedFetch(`/devices/${device.id}/status`);
        const statusData = await statusResponse.json();

        if (statusData.status === !isOn) {
          clearInterval(pollInterval);
          setIsOn(!isOn);
          setIsToggling(false);
        } else if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setIsToggling(false);
          setToggleError(true);
        }
      }, 2000);
    } catch (error) {
      console.error('Error toggling device:', error);
      setIsToggling(false);
      setToggleError(true);
    }
  };
  const quickToggleDevice = async () => {

    if (isToggling) return;
    
    setIsToggling(true);
    setToggleError(false);
    
    try {
      const response = await authenticatedFetch(`/toggle_device`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ device_id: device.id, state: !isOn }),
      });

      if (!response.ok) throw new Error('Seadme lülitamine ebaõnnestus');

      let attempts = 0;
      const maxAttempts = 5;
      const pollInterval = setInterval(async () => {
        attempts++;
        const statusResponse = await authenticatedFetch(`/devices/${device.id}/status`);
        const statusData = await statusResponse.json();

        if (statusData.status === !isOn) {
          clearInterval(pollInterval);
          setIsOn(!isOn);
          setIsToggling(false);
        } else if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setIsToggling(false);
          setToggleError(true);
        }
      }, 2000);
    } catch (error) {
      console.error('Error toggling device:', error);
      setIsToggling(false);
      setToggleError(true);
    }
  };
  const handleDelete = async () => {
    try {
      const response = await authenticatedFetch(`/devices/${device.id}`, { method: 'DELETE' });
      if (!response.ok) throw new Error('Seadme kustutamine ebaõnnestus');
      onDeviceDelete(device.id);
    } catch (error) {
      console.error('Error deleting device:', error);
      setDeleteError('Seadme kustutamine ebaõnnestus. Proovige uuesti.');
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const AutomationModeSelector = ({ automationMode, setAutomationMode, setHasUnsavedChanges }) => {
    const modes = [
      { value: 'piirhind', label: 'Piirhind', icon: FlipVertical },
      { value: 'boiler', label: 'Boiler', icon: Waves },
      { value: 'consecutive', label: 'Järjest', icon: Clock },
      { value: 'manual', label: 'Graafik', icon: CalendarRange }
    ];
  
    return (
      <div className="inline-flex pt-2 rounded-md shadow-sm" role="group">
        {modes.map((mode, index) => {
          const Icon = mode.icon;
          return (
            <button
              key={mode.value}
              onClick={() => {
                setAutomationMode(mode.value);
                setHasUnsavedChanges(true);
              }}
              className={`
                px-2 py-2 text-xs font-medium
                ${automationMode === mode.value
                  ? 'bg-gray-700 text-gray-300 '
                  : 'bg-gray-700 text-gray-300  opacity-50'
                }
                ${index === 0 ? 'rounded-l-md' : ''}
                ${index === modes.length - 1 ? 'rounded-r-md' : ''}
                ${index !== 0 ? '-ml-px' : ''}
                border border-gray-600
                focus:z-10 focus:ring-2 focus:ring-brand focus:outline-none
                transition-colors duration-200
              `}
            >
              <div className="flex items-center justify-center">
                <Icon size={16} className="mr-1" />
                {mode.label}
              </div>
            </button>
          );
        })}
      </div>
    );
  };
  
  const renderBoilerTimeSelection = () => {
    const currentSetTimes = settings.boiler?.set_time || [];
  
    const addTime = () => {
      const availableHours = [...Array(24).keys()].filter(
        (hour) => !currentSetTimes.includes(hour)
      );
      if (availableHours.length === 0) return;
      const newTime = availableHours[0];
      setSettings({
        ...settings,
        boiler: {
          ...settings.boiler,
          set_time: [...currentSetTimes, newTime].sort((a, b) => a - b),
        },
      });
      setHasUnsavedChanges(true);
    };
  
    const removeTime = (timeToRemove) => {
      setSettings({
        ...settings,
        boiler: {
          ...settings.boiler,
          set_time: currentSetTimes.filter((time) => time !== timeToRemove),
        },
      });
      setHasUnsavedChanges(true);
    };
  
    const updateTime = (index, newValue) => {
      const updatedTimes = [...currentSetTimes];
      updatedTimes[index] = newValue;
      setSettings({
        ...settings,
        boiler: { ...settings.boiler, set_time: updatedTimes.sort((a, b) => a - b) },
      });
      setHasUnsavedChanges(true);
    };
  
    return (
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Waves size={20} className="text-gray-400" />
          <label className="text-sm text-gray-400">Boiler peaks olema soe:</label>
        </div>
  
        {currentSetTimes.length === 0 && (
          <div className="text-sm text-gray-400">Lisa kellaaeg, millal boiler peaks töötama</div>
        )}
  
        {currentSetTimes.map((time, index) => (
          <div
            key={index}
            className="bg-[#2b3544] border border-gray-700 p-3 rounded-md shadow max-w-md md:max-w-80"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-400 font-normal">
                kui kell saab <span className="text-gray-200 font-bold">{time.toString().padStart(2, "0")}:00</span>
              </span>
              <button
                onClick={() => removeTime(time)}
                className="text-red-500 hover:text-red-400 transition-colors duration-200"
              >
                <Trash2 size={16} />
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <div className="flex items-center space-x-4">
                {/* Slider Container */}
                <div className="flex-grow px-4">
                  <input
                    type="range"
                    min="0"
                    max="23"
                    value={time}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value, 10);
                      updateTime(index, newValue);
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      const newValue = parseInt(touch.target.value, 10);
                      updateTime(index, newValue);
                    }}
                    className="slider w-full h-2 bg-gray-300 rounded-lg cursor-pointer"
                  />
                </div>
              </div>
  
              {/* Time Markers */}
              <div className="flex justify-between text-xs text-gray-400 mt-2">
                <span onClick={() => updateTime(index, 0)} className="cursor-pointer">00:00</span>
                <span onClick={() => updateTime(index, 6)} className="cursor-pointer">06:00</span>
                <span onClick={() => updateTime(index, 12)} className="cursor-pointer">12:00</span>
                <span onClick={() => updateTime(index, 18)} className="cursor-pointer">18:00</span>
                <span onClick={() => updateTime(index, 23)} className="cursor-pointer">23:00</span>
              </div>
            </div>
  
            <style jsx>{`
              .slider {
                -webkit-appearance: none;
                appearance: none;
                width: 100%;
                height: 8px;
                background: #4b5563;
                border-radius: 999px;
                outline: none;
                opacity: 0.7;
                transition: opacity 0.2s;
              }
  
              .slider:hover {
                opacity: 1;
              }
  
              .slider::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 8px;
                height: 20px;
                border-radius: 4px;
                background: #adb3bb;
                border-color: #adb3bb;
                cursor: pointer;
              }
  
              .slider::-moz-range-thumb {
                width: 8px;
                height: 20px;
                border-radius: 4px;
                background: #adb3bb;
                border-color: #adb3bb;
                cursor: pointer;
              }
            `}</style>
          </div>
        ))}
  
        <button
          onClick={addTime}
          className="mt-4 px-4 py-2 bg-gray-700 text-white text-sm rounded hover:bg-gray-600 transition-colors duration-200 flex items-center justify-center"
          disabled={currentSetTimes.length >= 24}
        >
          <Plus size={16} className="mr-2" /> Lisa kellaaeg
        </button>
      </div>
    );
  };
  

  const renderAutomationSettings = () => {
    return (
      <div className="space-y-4">
        <AutomationModeSelector
          automationMode={automationMode}
          setAutomationMode={setAutomationMode}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
        
      {automationMode === 'piirhind' && (
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <FlipVertical size={20} className="text-gray-400" />
          <label className="text-sm text-gray-400">Piirhind (s/kWh):</label>
          <input
            type="text"
            inputMode="numeric"
            value={settings.piirhind?.max_price !== undefined ? settings.piirhind.max_price : ''}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || !isNaN(parseFloat(value))) {
                setSettings({
                  ...settings,
                  piirhind: { ...settings.piirhind, max_price: value === '' ? '' : parseFloat(value) }
                });
                setHasUnsavedChanges(true);
              }
            }}
            className="w-20 bg-gray-700 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-brand"
          />
        </div>
    <div className="flex items-center space-x-2">
      <Hourglass size={20} className="text-gray-400" />
      <label className="text-sm text-gray-400">Minimaalsed töötunnid:</label>
      <div className="relative inline-block">
        <input
          type="checkbox"
          id="use-min-hours"
          checked={settings.piirhind?.use_min_hours || false}
          onChange={(e) => {
            const useMinHours = e.target.checked;
            setSettings({
              ...settings,
              piirhind: {
                ...settings.piirhind,
                use_min_hours: useMinHours,
                min_hours: useMinHours ? (settings.piirhind?.min_hours ?? 0) : 0
              }
            });
            setHasUnsavedChanges(true);
          }}
          className="sr-only"
        />
        <label
          htmlFor="use-min-hours"
          className={`flex items-center justify-center w-6 h-6 border-1 rounded cursor-pointer transition-colors duration-200 ease-in-out focus-within:ring-2 focus-within:ring-brand ${
            settings.piirhind?.use_min_hours ? 'bg-brand border-brand' : 'bg-gray-700 border-gray-400'
          }`}
        >
          {settings.piirhind?.use_min_hours && <Check size={18} className="text-white" />}
        </label>
      </div>
      {settings.piirhind?.use_min_hours && (
        <input
        style={{ width: '50px' }}
        type="text"
        inputMode='numeric'
        value={settings.piirhind?.min_hours !== undefined ? settings.piirhind.min_hours : ''}
        onChange={(e) => {
          const value = e.target.value;
          if (value === '' || !isNaN(parseFloat(value))) {
            setSettings({
              ...settings,
              piirhind: { ...settings.piirhind, min_hours: value === '' ? null : parseFloat(value) }
            });
            setHasUnsavedChanges(true);
          }
        }}
        className="w-7 bg-gray-700 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-brand"
      />
      )}
      
    </div>
  </div>
)}

  
        {automationMode === 'boiler' && (
          <div className="space-y-4">
            {renderBoilerTimeSelection()}
            <div className="mb-2 flex items-center">
              <div className="flex items-center">
                <Thermometer size={20} className="text-gray-400" />
                <label className="text-sm text-gray-400 mr-2">Vesi soojeneb: (h)</label>
              </div>
              <input
                type="text"
                inputMode="numeric"
                value={settings.boiler?.required_hours || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (!isNaN(value) && parseInt(value) >= 1 && parseInt(value) <= 24)) {
                    setSettings({
                      ...settings,
                      boiler: { ...settings.boiler, required_hours: value === '' ? '' : parseInt(value) }
                    });
                    setHasUnsavedChanges(true);
                  }
                }}
                min="1"
                max="24"
                className="w-20 bg-gray-700 text-white rounded p-1 focus:outline-none focus:ring-2 focus:ring-brand"
              />
            </div>
          </div>
        )}
  
        {automationMode === 'consecutive' && (
          <div className="space-y-4">
            <div className="mb-2 flex items-center">
              <div className="flex items-center">
                <Hourglass size={20} className="text-gray-400" />
                <label className="text-sm text-gray-400 mr-2">Tahan tarbida: (h)</label>
              </div>
              <input
              type="text"
              inputMode="numeric"
              value={settings.consecutive?.amount || ''}
              onChange={(e) => {
                const value = e.target.value;

                // Kui väärtus pole tühi, parsime selle; vastasel juhul jätame selle tühjaks
                setSettings({
                  ...settings,
                  consecutive: { 
                    ...settings.consecutive, 
                    amount: value === '' ? '' : parseInt(value)
                  }
                });
                
                setHasUnsavedChanges(true);
              }}
              min="1"
              max="24"
              className="w-20 bg-gray-700 text-white rounded p-1 focus:outline-none focus:ring-2 focus:ring-brand"
            />
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => {
                  setSettings({
                    ...settings,
                    consecutive: { ...settings.consecutive, beforeMorning: !settings.consecutive?.beforeMorning }
                  });
                  setHasUnsavedChanges(true);
                }}
                className={`px-3 py-1 rounded-md text-sm ${
                  settings.consecutive?.beforeMorning
                    ? 'bg-brand text-gray-900'
                    : 'bg-gray-700 text-white'
                }`}
              >
                Enne hommikut
              </button>
              <span className="text-sm text-gray-400">
                {settings.consecutive?.beforeMorning ? 'Enne kella 06:00' : 'Mistahes ajal'}
              </span>
            </div>
          </div>
        )}
  
  {automationMode === 'manual' && (
  <div className="space-y-4">
    {settings.manual?.timers?.map((timer, index) => (
      <div key={index} className="max-w-72 bg-[#2b3544] border border-gray-700 p-3 rounded-md shadow">
        <div className="flex items-center justify-between mb-2">
          <span className="text-sm text-gray-400">Lülita seade kell </span>
          <button
            onClick={() => {
              const newTimers = settings.manual.timers.filter((_, i) => i !== index);
              setSettings({
                ...settings,
                manual: { ...settings.manual, timers: newTimers }
              });
              setHasUnsavedChanges(true);
            }}
            className="text-red-500 hover:text-red-400 transition-colors duration-200"
          >
            <Trash2 size={16} />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <input
            type="time"
            value={timer.time}
            onChange={(e) => {
              const newTimers = [...(settings.manual?.timers || [])];
              newTimers[index].time = e.target.value;
              setSettings({
                ...settings,
                manual: { ...settings.manual, timers: newTimers }
              });
              setHasUnsavedChanges(true);
            }}
            className="bg-gray-700 text-white rounded p-1"
          />

          {/* Multibutton for Action Selection */}
          <div className="inline-flex rounded-md shadow-sm" role="group">
            {['on', 'off'].map((action) => (
              <button
                key={action}
                onClick={() => {
                  const newTimers = [...(settings.manual?.timers || [])];
                  newTimers[index].action = action;
                  setSettings({
                    ...settings,
                    manual: { ...settings.manual, timers: newTimers }
                  });
                  setHasUnsavedChanges(true);
                }}
                className={`
                  px-3 py-2 text-sm font-medium
                  ${timer.action === action
                    ? 'bg-gray-700 text-gray-300' // Selected action
                    : 'bg-gray-700 text-gray-300 opacity-50' // Non-selected action
                  }
                  ${action === 'on' ? 'rounded-l-md rounded-r-none' : 'rounded-r-md rounded-l-none'} // Left button rounded, right button rounded
                  border border-gray-600
                  focus:z-10 focus:ring-2 focus:ring-brand focus:outline-none
                  transition-colors duration-200
                `}
              >
                {action === 'on' ? 'Sisse' : 'Välja'}
              </button>
            ))}
          </div>
        </div>
      </div>
    ))}
    <button
      onClick={() => {
        const newTimers = [...(settings.manual?.timers || []), { time: '00:00', action: 'on', enabled: true }];
        setSettings({
          ...settings,
          manual: { ...settings.manual, timers: newTimers }
        });
        setHasUnsavedChanges(true);
      }}
      className="mt-2 px-4 py-2 bg-gray-700 text-white text-sm rounded hover:bg-gray-600 transition-colors duration-200 flex items-center justify-center"
    >
      <Plus size={16} className="mr-2" /> Lisa taimer
    </button>
  </div>
)}
      </div>
    );
  };
  const renderDeviceStatus = () => {
    if (isToggling) {
      return (
        <div className="flex items-center w-full">
          <div className="flex items-center min-w-0 mr-2">
            <Loader size={12} className="mr-1 flex-shrink-0 animate-spin" />
            <span className="truncate text-gray-400">Lülitamine...</span>
          </div>
        </div>
      );
    }

    if (isOnline) {
      const statusIcon = device.automation_enabled
        ? (nextScheduledAction ? <CalendarClock size={12} className="mr-1 flex-shrink-0" /> : <Info size={12} className="mr-1 flex-shrink-0" />)
        : <Snowflake size={12} className="mr-1 flex-shrink-0" />;
      
      const statusText = device.automation_enabled
        ? (nextScheduledAction ? `Lülitan ${nextAction} ${countdown} pärast` : 'Automaatrežiim aktiivne')
        : 'Automaatrežiim peatatud';

        return (
          <div className="flex items-center w-full">
            <div className="flex items-center min-w-0 mr-2">
              {statusIcon}
              <span className="truncate">{statusText}</span>
            </div>
            {isOn ? (
              <>
                <span className="text-brand flex items-center flex-shrink-0">
                  <Zap size={12} className="mr-1" />
                  {powerConsumption.toFixed(0)}W
                </span>
                <span className="text-yellow-400 flex items-center flex-shrink-0 ml-2">
                  <Coins size={12} className="mr-1" />
                  {hourlyCost.toFixed(1)} senti/h
                </span>
              </>
            ) : (
              <span className="text-gray-400 flex items-center flex-shrink-0">
                <Zap size={12} className="mr-1" />
                Seade väljas
              </span>
            )}
          </div>
        );
    } else if (lastOnline) {
      return (
        <div className="flex items-center text-red-500">
          <Unplug size={12} className="mr-1 flex-shrink-0" />
          <span className="truncate">
            {isExpanded
              ? `Ühendus katkes ${isToday(parseISO(lastOnline.slice(0,-1))) ? 'täna' : isTomorrow(parseISO(lastOnline.slice(0,-1))) ? 'eile' : format(parseISO(lastOnline.slice(0,-1)), 'dd.MM.yyyy')} kell ${format(parseISO(lastOnline.slice(0,-1)), 'HH:mm')}.`
              : `Ühendus katkes ${formatDistanceToNow(parseISO(lastOnline.slice(0,-1)), { addSuffix: true, locale: et })}.`
            }
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex items-center text-yellow-500">
          <AlertTriangle size={12} className="mr-1 flex-shrink-0" />
          <span className="truncate">Seade pole veel ühendatud.</span>
        </div>
      );
    }
  };

  const renderSchedule = () => {
    if (!isAutomationEnabled) {
      return (
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-400">Automaatrežiim on välja lülitatud.</p>
          <p className="text-xs text-gray-500">Lülitage see sisse, et näha tulevasi tegevusi.</p>
        </div>
      );
    }

    const now = new Date();
    const upcomingActions = scheduleData
      .filter(action => parseISO(action.timestamp) > now)
      .slice(0, 5);

    if (upcomingActions.length === 0) {
      return (
        <div className="mt-4 text-center">
          <p className="text-xs text-gray-400">Lähiajal pole graafikus ühtegi tegevust.</p>
        </div>
      );
    }

    return (
      <div className="mt-4">
        <h4 className="text-sm font-semibold text-white mb-2">Tulevased tegevused:</h4>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-300">
            <thead className="text-xs uppercase bg-gray-700">
              <tr>
                <th className="px-4 py-2">Kuupäev</th>
                <th className="px-4 py-2">Kellaaeg</th>
                <th className="px-4 py-2">Tegevus</th>
              </tr>
            </thead>
            <tbody>
              {upcomingActions.map((action, index) => {
                const actionTime = parseISO(action.timestamp);
                const formattedDate = isToday(actionTime) 
                  ? 'Täna'
                  : isTomorrow(actionTime)
                    ? 'Homme'
                    : format(actionTime, 'dd. MMMM', { locale: et });
                const formattedTime = format(actionTime, 'HH:mm');
                return (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-750'}>
                    <td className="px-4 py-2">{formattedDate}</td>
                    <td className="px-4 py-2">{formattedTime}</td>
                    <td className={`px-4 py-2 font-medium ${action.state ? 'text-green-400' : 'text-red-400'}`}>
                      {action.state ? 'Sisse' : 'Välja'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-800 p-3 rounded-xl shadow-lg transition-all duration-300 hover:shadow-brand/20 border border-gray-700">
      <div onClick={() => setIsExpanded(!isExpanded)}   className="flex items-center space-x-3">
        <div
          onClick={(e) => {
            e.stopPropagation()
            quickToggleDevice()
          }}
             className="bg-gray-700 p-2 rounded-lg flex-shrink-0">
          <Power size={20} className={isOn ? "text-brand" : "text-gray-400"} />
        </div>
        <div className="flex-grow min-w-0">
          {isEditingName ? (
            <div className="flex items-center mb-1">
              <input
                type="text"
                value={tempDeviceName}
                onChange={(e) => setTempDeviceName(e.target.value)}
                className="text-sm w-20 md:w-auto font-semibold text-white bg-gray-700 border border-gray-600 rounded px-2 py-1 mr-2 focus:outline-none focus:ring-2 focus:ring-brand"
              />
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleNameChange(tempDeviceName);
                  setIsEditingName(false);
                }}
                className="text-green-500 hover:text-green-400 mr-2"
              >
                <Check size={16} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setTempDeviceName(deviceName);
                  setIsEditingName(false);
                }}
                className="text-red-500 hover:text-red-400"
              >
                <X size={16} />
              </button>
            </div>
          ) : (
            <div className="flex items-center mb-1">
              <h3 className="text-base font-semibold text-white truncate mr-2">{deviceName}</h3>
              {isExpanded && !isDemoMode && (
              <button
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsEditingName(true)
                  }}
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                >
                  <Pencil size={14} />
                </button>
              )}
            </div>
          )}
          <div className="text-xs text-gray-400 overflow-hidden">
            <div className="truncate scrolling-text">
              {renderDeviceStatus()}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {lastOnline && (
            <Tippy content={isAutomationEnabled ? "Automaatrežiim aktiivne" : (isOn ? "Lülita välja": "Lülita sisse") }>
              <button
               onClick={(e) => {
                e.stopPropagation()
                toggleDevice()
              }}
                className={`w-10 h-5 rounded-full p-1 transition-colors duration-200 relative ${
                  isOn ? 'bg-brand' : 'bg-gray-600'
                } ${isAutomationEnabled || isToggling ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                disabled={isToggling}
              >
                <div 
                  className={`w-3 h-3 rounded-full bg-white transition-transform duration-200 ${
                    isOn ? 'translate-x-5' : ''
                  }`}
                />
                {isAutomationEnabled && (
                  <Lock size={10} className={`absolute top-1/2 left-1/2 transform
                    ${isOn ? '-translate-x-3' : 'translate-x-1'} -translate-y-1/2 ${
                    isOn ? 'text-white' : 'text-gray-400'
                  }`}/>
                )}
              </button>
            </Tippy>
          )}
          <Tippy content={isExpanded ? "Näita vähem" : "Näita rohkem"}>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="p-1 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-200"
            >
              {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>
          </Tippy>
        </div>
      </div>
      
      {toggleError && (
        <p className="text-sm text-red-400 mt-2">Lülitamine ebaõnnestus! Palun proovige uuesti.</p>
      )}
      {isExpanded && !lastOnline && (
        <div className="mt-4 border-t border-gray-700 pt-4">
          <h4 className="text-sm font-semibold text-white mb-2">Seadme ühendamine koduvõrguga</h4>
          <ol className="text-sm text-gray-300 list-decimal list-inside space-y-2">
            <li>Veenduge, et seade on ühendatud vooluvõrku.</li>
            <li>Oodake, kuni tekib saadavale WiFi võrk nimega volton-..., millega te oma nutiseadme või arvuti saate ühendada.</li>
            <li>Seejärel avaneb teile aken, kus saate Voltoni seadme seadistada oma koduvõrku kasutama.</li>
            <li>Pärast edukat võrguühendust:
              <ul className="list-disc list-inside ml-4 mt-1">
                <li>Veenduge, et teie arvuti või nutiseade on tagasi koduvõrgus.</li>
                <li>Avage Voltoni juhtpaneel ja kontrollige seadme toimimist.</li>
              </ul>
            </li>
            <li>Probleemide korral:
              <ul className="list-disc list-inside ml-4 mt-1">
                <li>Vajutage <strong>Unusta seade</strong>.</li>
                <li>Proovige uuesti ühendada, kontrollides seadme koodi.</li>
              </ul>
            </li>
          </ol>
          <div className="mt-4 flex justify-between items-center">
            <p className="text-sm text-gray-400">Kui teil tekkib seadme ühendamisega probleeme, võtke ühendust klienditoega.</p>
          </div>
          <div className="mt-4">
            <Tippy content="Unusta seade">
              <button
                onClick={() => setShowDeleteConfirmation(true)}
                className="text-sm text-red-500 hover:text-red-400 transition-colors duration-200 flex items-center"
              >
                <Trash2 size={16} className="mr-1" />
                Unusta seade
              </button>
            </Tippy>
          </div>
        </div>
      )}
      {isExpanded && lastOnline && (
        <div className="mt-4 border-t border-gray-700 pt-4">
          <div className="mb-2 flex items-center">
            <Clock size={20} className="text-gray-400 mr-2" />
            <label className="text-sm text-gray-400 mr-7">Automaatika:</label>
            {isAutomationToggling ? (
              <div className="flex items-center text-gray-400">
                <Loader size={16} className="mr-2 animate-spin" />
              </div>
            ) : (
              <Tippy content={isAutomationEnabled ? "Lülita automaatrežiim välja" : "Lülita automaatrežiim sisse"}>
                <button
                  onClick={handleAutomationToggle}
                  className={`w-10 h-5 rounded-full p-1 transition-colors duration-200 relative ${
                    isAutomationEnabled ? 'bg-brand' : 'bg-gray-600'
                  }`}
                >
                  <div 
                    className={`w-3 h-3 rounded-full bg-white transition-transform duration-200 ${
                      isAutomationEnabled ? 'translate-x-5' : ''
                    }`}
                  />
                </button>
              </Tippy>
            )}
          </div>
           
          {renderAutomationSettings()}
          {renderSchedule()}
          <div className="mt-4 flex justify-between items-center gap-4">
            <div className="flex space-x-2">
            {!isDemoMode && (<Tippy content="Kustuta seade">
                <button
                  onClick={() => setShowDeleteConfirmation(true)}
                  className="text-sm text-red-500 hover:text-red-400 transition-colors duration-200 flex items-center"
                >
                  <Trash2 size={16} className="mr-1" />
                  Kustuta seade
                </button>
              </Tippy>
            )}
              <Tippy content="Vaata seadme üksikasju">
                <button
                  onClick={() => setShowDetailsModal(true)}
                  className="text-sm text-gray-400 hover:text-blue-400 transition-colors duration-200 flex items-center"
                >
                  <Info size={16} className="mr-1" />
                  Üksikasjad
                </button>
              </Tippy>
            </div>
            {hasUnsavedChanges && (
              <div className="flex space-x-2">
                {isUpdating ? (
                  <div className="flex items-center text-brand">
                    <Loader size={16} className="mr-2 animate-spin" />
                    Salvestamine...
                  </div>
                ) : (
                  <>
                    <button
                      onClick={handleDiscard}
                      className="text-sm text-gray-400 hover:text-white transition-colors duration-200 flex items-center"
                    >
                      <X size={16} className="mr-1" />
                      Tühista
                    </button>
                    <button
                      onClick={handleSave}
                      className="text-sm text-brand hover:text-brand/80 transition-colors duration-200 flex items-center"
                    >
                      <Save size={16} className="mr-1" />
                      Salvesta
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {showDetailsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-xl shadow-lg m-6 border border-gray-700 max-w-md w-full">
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
              <Info size={24} className="mr-2 text-blue-500" />
              Seadme üksikasjad
            </h3>
            <div className="space-y-4">
              <div className="flex items-center">
                {renderWifiIcon(device.network_info?.signal)}
                <span className="ml-2 text-gray-300">WiFi signaal: {device.network_info?.signal} {device.network_info?.signal === undefined ? "puudub" : "dBm"}</span>
              </div>
              <div className="flex items-center">
                <Globe size={20} className="text-gray-400" />
                <span className="ml-2 text-gray-300">SSID: {device.network_info?.ssid || 'N/A'}</span>
              </div>
              <div className="flex items-center">
                <Network size={20} className="text-gray-400" />
                <span className="ml-2 text-gray-300">IP Address: {device.network_info?.ip_address || 'N/A'}</span>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowDetailsModal(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-500 transition-colors duration-200"
              >
                Sulge
              </button>
            </div>
          </div>
        </div>
      )}
      {showDisableAutomationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-xl shadow-lg m-6 border border-gray-700 max-w-md w-full">
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
              <AlertTriangle size={24} className="mr-2 text-yellow-500" />
              Automaatrežiim on aktiivne
            </h3>
            <p className="text-gray-300 mb-4">
              Seadme kontrollimiseks tuleb automaatrežiim välja lülitada. Kui kauaks te selle välja lülitada soovite?
            </p>
            <select
              value={selectedDisableOption}
              onChange={(e) => setSelectedDisableOption(e.target.value)}
              className="w-full mb-4 bg-gray-700 text-white rounded p-2 focus:outline-none focus:ring-2 focus:ring-brand"
            >
              <option disabled value="15min">15 minutiks (Tulekul)</option>
              <option disabled value="30min">30 minutiks (Tulekul)</option>
              <option disabled value="60min">1 tunniks (Tulekul)</option>
              <option disabled value="180min">3 tunniks (Tulekul)</option>
              <option disabled value="360min">6 tunniks (Tulekul)</option>
              <option disabled value="720min">12 tunniks (Tulekul)</option>
              <option disabled value="1440min">24 tunniks (Tulekul)</option>
              <option value="until_reenabled">Kuni selle taas sisse lülitan</option>
            </select>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDisableAutomationModal(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-500 transition-colors duration-200"
              >
                Tühista
              </button>
              <button
                onClick={async () => {
                  setShowDisableAutomationModal(false);
                  await updateAutomationState(false);
                }}
                className="px-4 py-2 bg-brand text-gray-900 rounded-md hover:bg-brand/80 transition-colors duration-200"
              >
                Kinnita
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex  items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-2xl shadow-lg m-6 border border-gray-700 max-w-md w-full">
            <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
              <AlertTriangle size={24} className="mr-2 text-yellow-500" />
              Kinnita kustutamine
            </h3>
            <p className="text-gray-300 mb-6">
              Kas olete kindel, et soovite seadme "{deviceName}" kustutada? See toiming on pöördumatu.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-500 transition-colors duration-200"
              >
                Tühista
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition-colors duration-200"
              >
                Kustuta
              </button>
            </div>
          </div>
        </div>
      )}
      {deleteError && (
        <p className="mt-2 text-sm text-red-500">{deleteError}</p>
      )}
    </div>
  );
};

export default DeviceStatus;
