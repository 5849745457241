import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from "supertokens-auth-react/recipe/session";
import { User, LogOut } from 'lucide-react';
import { authenticatedFetch } from '../api';

const isDemoMode = process.env.REACT_APP_DEMO === 'true';

const Profile = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('demo@volton.ee');

  useEffect(() => {
    if (!isDemoMode) {
      const fetchUserInfo = async () => {
        try {
          const response = await authenticatedFetch('/userinfo');
          if (response.ok) {
            const data = await response.json();
            setUserEmail(data.email);
          } else {
            console.error('Failed to fetch user info');
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      };

      fetchUserInfo();
    }
  }, []);

  const handleLogout = async () => {
    if (isDemoMode) {
      navigate('/');
    } else {
      await signOut();
      navigate('/auth');
    }
  };

  useEffect(() => {
    document.title = "Seaded | Volton"; // Muudab lehe title
  }, []);

  const BuildDateOverlay = () => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const buildDate = process.env.REACT_APP_BUILD_DATE 
      ? new Date(process.env.REACT_APP_BUILD_DATE).toLocaleString()
      : '';
  
    if (!isDevelopment) return null;
  
    return (
      <div className="overlay">
        <p>{buildDate}</p>
      </div>
    );
  };

  return (
    <div className="bg-gray-900 background-grid min-h-screen flex items-center justify-center">
      <div className="bg-gray-800 p-8 pb-6 rounded-2xl shadow-lg border border-gray-700 max-w-md w-full flex flex-col">
        <h2 className="text-lg font-semibold mb-4 text-white flex items-center">
          <User size={24} className="mr-3 text-brand" />
          Konto seaded
        </h2>
        <div className="mb-6 text-sm text-white">
          <p><strong>E-post:</strong> {userEmail}</p>
        </div>
        { !isDemoMode && (
        <button
          onClick={handleLogout}
          className="w-full text-red-500 text-sm rounded-md hover:text-red-500/80 transition-colors duration-200 flex"
        >
          Logi välja
          <LogOut size={20} className="ml-2" />
        </button>
        )}
      </div>
      <BuildDateOverlay />
    </div>
  );
};

export default Profile;