import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, BarChart2, Settings, MessageSquare } from 'lucide-react';

const NavItem = ({ Icon, id, active, onClick, label }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <button
        onClick={onClick}
        aria-label={label}
        className={`p-2.5 md:p-3 rounded-lg transition-colors duration-200 focus:outline-none ${
          active ? 'bg-brand text-gray-900' : 'text-gray-400 hover:bg-gray-700 focus:ring-2 focus:ring-blue-500'
        }`}
      >
        <Icon size={15} className="md:w-5 md:h-5" />
      </button>
      {}
      {showTooltip && (
        <div className="hidden md:block absolute left-12 md:left-20 bg-gray-700 text-white text-sm p-2 rounded-md shadow-lg z-50 opacity-75">
          {label}
        </div>
      )}
    </div>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { icon: Home, id: 'dashboard', path: '/dashboard', label: 'Juhtpaneel' },
    { icon: BarChart2, id: 'history', path: '/history', label: 'Tarbimisajalugu' },
    { icon: MessageSquare, id: 'feedback', path: '/feedback', label: 'Tagasiside' },
    { icon: Settings, id: 'settings', path: '/profile', label: 'Seaded' },
  ];

  return (
    <nav className="bg-gray-800 w-full h-12 md:w-16 flex flex-row md:flex-col items-center justify-around md:justify-start px-2 py-4 md:py-8 md:space-y-8 fixed bottom-0 md:left-0 md:h-full z-10">
      {navItems.map(({ icon: Icon, id, path, label }) => (
        <NavItem
          key={id}
          Icon={Icon}
          active={location.pathname === path}
          onClick={() => navigate(path)}
          label={label}
        />
      ))}
    </nav>
  );
};

export default Sidebar;
