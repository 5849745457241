import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import * as reactRouterDom from "react-router-dom";
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import Feedback from './components/Feedback';
import History from './components/History';
import Sidebar from './components/Sidebar';

const isDemoMode = process.env.REACT_APP_DEMO === 'true';

let SuperTokensWrapper, getSuperTokensRoutesForReactRouterDom, ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI, SessionAuth, SuperTokens, Session, ThirdParty, Google, EmailPassword, EmailVerification;

// Conditionally initialize SuperTokens
if (!isDemoMode) {
  ({ SuperTokensWrapper } = require("supertokens-auth-react"));
  ({ getSuperTokensRoutesForReactRouterDom } = require("supertokens-auth-react/ui"));
  ({ ThirdPartyPreBuiltUI } = require('supertokens-auth-react/recipe/thirdparty/prebuiltui'));
  ({ EmailPasswordPreBuiltUI } = require('supertokens-auth-react/recipe/emailpassword/prebuiltui'));
  ({ SessionAuth } = require("supertokens-auth-react/recipe/session"));
  SuperTokens = require("supertokens-auth-react").default;
  Session = require("supertokens-auth-react/recipe/session").default;
  ThirdParty = require("supertokens-auth-react/recipe/thirdparty").default;
  ({ Google } = require("supertokens-auth-react/recipe/thirdparty"));
  EmailPassword = require("supertokens-auth-react/recipe/emailpassword").default;
  EmailVerification = require("supertokens-auth-react/recipe/emailverification").default;

  SuperTokens.init({
    appInfo: {
        // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
        appName: "volton",
        apiDomain: process.env.REACT_APP_API_URL,
        websiteDomain: process.env.REACT_APP_WEBSITE_URL,
        apiBasePath: process.env.REACT_APP_API_URL.split('/').slice(3).join('/'),
        websiteBasePath: "/login"
    },
    recipeList: [
        ThirdParty.init({
            signInAndUpFeature: {
                providers: [
                    Google.init()
                ]
            }
        }),
        EmailPassword.init(),
        EmailVerification.init({
            mode: "REQUIRED", // or "OPTIONAL"
        }),
        Session.init()
    ],
    languageTranslations: { // This object contains all translation related options
      translations: { // These are the displayed translation strings for each language
      
          et: {
                  AUTH_PAGE_HEADER_TITLE_SIGN_IN_AND_UP: "Looge konto / Logige sisse",
                  AUTH_PAGE_HEADER_TITLE_SIGN_IN: "Sisselogimine",
                  AUTH_PAGE_HEADER_TITLE_SIGN_UP: "Registreerumine",
      
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_START: "Pole veel kontot?",
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_SIGN_UP_LINK: "Registreeru kasutajaks",
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_END: "",
      
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_START: "Kas sul on juba konto?",
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_SIGN_IN_LINK: "Logi sisse",
                  AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_END: "",
      
                  AUTH_PAGE_FOOTER_START: "Jätkates nõustud meie ",
                  AUTH_PAGE_FOOTER_TOS: "Kasutustingimustega",
                  AUTH_PAGE_FOOTER_AND: " ja ",
                  AUTH_PAGE_FOOTER_PP: "Privaatsuspoliitikaga",
                  AUTH_PAGE_FOOTER_END: "",
      
                  DIVIDER_OR: "või",
      
                  THIRD_PARTY_PROVIDER_DEFAULT_BTN_START: "Jätka ",
                  THIRD_PARTY_PROVIDER_DEFAULT_BTN_END: "-ga",
      
                  THIRD_PARTY_ERROR_NO_EMAIL: "E-posti aadressi ei õnnestunud hankida. Palun proovi teist meetodit.",
                  BRANDING_POWERED_BY_START: "",
                  BRANDING_POWERED_BY_END: "",
                  SOMETHING_WENT_WRONG_ERROR: "Midagi läks valesti. Palun proovi uuesti.",
                  SOMETHING_WENT_WRONG_ERROR_RELOAD: "Midagi läks valesti. Palun proovi hiljem uuesti või laadi leht uuesti.",
                  EMAIL_VERIFICATION_RESEND_SUCCESS: "E-kiri saadetud uuesti",
                  EMAIL_VERIFICATION_SEND_TITLE: "Kinnita oma e-posti aadress",
                  EMAIL_VERIFICATION_SEND_DESC_START: "",
                  EMAIL_VERIFICATION_SEND_DESC_STRONG: "Palun klõpsa lingil",
                  EMAIL_VERIFICATION_SEND_DESC_END: " e-kirjas, mille just sulle saatsime, et kinnitada oma e-posti aadress.",
                  EMAIL_VERIFICATION_RESEND_BTN: "Saada e-kiri uuesti",
                  EMAIL_VERIFICATION_LOGOUT: "Logi välja ",
                  EMAIL_VERIFICATION_SUCCESS: "E-posti aadressi kinnitamine õnnestus!",
                  EMAIL_VERIFICATION_CONTINUE_BTN: "JÄTKA",
                  EMAIL_VERIFICATION_CONTINUE_LINK: "Jätka",
                  EMAIL_VERIFICATION_EXPIRED: "E-posti kinnitamise link on aegunud",
                  EMAIL_VERIFICATION_ERROR_TITLE: "Midagi läks valesti",
                  EMAIL_VERIFICATION_ERROR_DESC:
                      "Tekkis ootamatu viga. Palun võta abi saamiseks ühendust klienditoega",
                  EMAIL_PASSWORD_EMAIL_LABEL: "E-post",
                  EMAIL_PASSWORD_EMAIL_PLACEHOLDER: "E-posti aadress",
      
                  EMAIL_PASSWORD_PASSWORD_LABEL: "Parool",
                  EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: "Parool",
      
                  EMAIL_PASSWORD_SIGN_IN_FORGOT_PW_LINK: "Unustasid parooli?",
                  EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: "Logi sisse",
                  EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: "Vale e-posti ja parooli kombinatsioon",
      
                  EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: "Loo konto",
      
                  EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: "See e-posti aadress on juba olemas. Palun logi selle asemel sisse",
      
                  EMAIL_PASSWORD_RESET_HEADER_TITLE: "Lähtesta oma parool",
                  EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: "Saadame teile e-kirja parooli lähtestamiseks",
                  EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: "teie konto",
                  EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: "Parooli lähtestamise e-kiri on saadetud aadressile ",
                  EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: ", kui see on meie süsteemis olemas. ",
                  EMAIL_PASSWORD_RESET_RESEND_LINK: "Saada uuesti või muuda e-posti aadressi",
                  EMAIL_PASSWORD_RESET_SEND_BTN: "Saada mulle e-kiri",
                  EMAIL_PASSWORD_RESET_SIGN_IN_LINK: "Logi sisse",
      
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: "Õnnestus!",
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: "Sinu parool on edukalt uuendatud",
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: "Logi sisse",
      
                  EMAIL_PASSWORD_NEW_PASSWORD_LABEL: "Uus parool",
                  EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: "Uus parool",
                  EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: "Kinnita parool",
                  EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: "Kinnita oma parool",
      
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: "Muuda oma parooli",
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: "Sisesta allpool uus parool oma parooli muutmiseks",
                  EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: "MUUDA PAROOLI",
                  EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: "Kehtetu parooli lähtestamise token",
      
                  ERROR_EMAIL_NON_STRING: "E-post peab olema string-tüüpi",
                  ERROR_EMAIL_INVALID: "E-post on vigane",
      
                  ERROR_PASSWORD_NON_STRING: "Parool peab olema string-tüüpi",
                  ERROR_PASSWORD_TOO_SHORT: "Parool peab sisaldama vähemalt 8 tähemärki, sealhulgas ühe numbri",
                  ERROR_PASSWORD_TOO_LONG: "Parooli pikkus peab olema vähem kui 100 tähemärki",
                  ERROR_PASSWORD_NO_ALPHA: "Parool peab sisaldama vähemalt ühe tähe",
                  ERROR_PASSWORD_NO_NUM: "Parool peab sisaldama vähemalt ühe numbri",
                  ERROR_CONFIRM_PASSWORD_NO_MATCH: "Kinnitusparool ei ühti",
      
                  ERROR_NON_OPTIONAL: "Väli ei ole valikuline"
          },
      },

      defaultLanguage: "et", 
  },
  style: `
        [data-supertokens~=container] {
            border: 1px solid rgb(55 65 81);
            --palette-background: 31, 41, 55;
            --palette-border: 55, 65, 81;
            --palette-inputBackground: 55, 65, 81;
            --palette-inputBorder: 55, 65, 81;
            --palette-textTitle: 255, 255, 255;
            --palette-textLabel: 255, 255, 255;
            --palette-textPrimary: 255, 255, 255;
            --palette-textGray: 156, 163, 175;
            --palette-error: 173, 46, 46;
            --palette-textInput: 156, 163, 175;
            --palette-textLink: 0, 209, 255;
            --palette-primary: 0, 209, 255;
            --palette-primaryBorder: 0, 209, 255;
            --palette-buttonText: 17, 24, 39;
            --palette-superTokensBrandingBackground: 31, 41, 55;
            --palette-superTokensBrandingText: 31, 41, 55;
            border-radius: 1rem;
            font-family: system-ui;
        }
        [data-supertokens~="button"][data-supertokens~="providerButton"] {
          background-color: #374151;
          border-color: rgb(75, 85, 99);
          color: white;
        }
        [data-supertokens~="button"][data-supertokens~="providerButton"]:hover {
          background-color: #374151;
        }
        [data-supertokens~="headerTitle"] {
          font-weight: 700;
        }
        [data-supertokens~="inputWrapper"] {
          height: 40px;
          border-color: rgb(75 85 99);
        }
        [data-supertokens~="button"] {
          font-family: inherit;
          height: 42px;
        }
        [data-supertokens~="label"] {
          font-weight: 500;
          color: rgb(156 163 175);
        }

    `,
});
}
const Header = () => {
  return (
    <header className="w-full flex flex-col mt-2 items-center">
      <svg className="w-24 h-24 mb-4" viewBox="0 0 333 333" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M119.684 47.5H48L106.5 284.5L177 285L119.684 47.5Z" fill="white" stroke="white" />
        <path d="M172.5 165L212 47.5L298 47L245 128H293L177.5 285L218 165H172.5Z" fill="white" />
      </svg>
      <h1 className="text-2xl font-semibold text-white flex items-center">
        Tere tulemast!
      </h1>
    </header>
  );
};


const Footer = () => {
  return (
    <footer className="w-full flex flex-col items-center">
      <p className="text-sm text-gray-400 mb-4 text-white items-center opacity-80">
        Kõrged elektrihinnad näpistavad?{' '}
        <a href="https://volton.ee/hinnad" target="_blank" rel="noreferrer" className="text-brand hover:underline ml-1">
          Soeta nutipistik.
        </a>
      </p>
      <p className="text-sm text-gray-400 text-white flex gap-2 items-center">
        <a href="https://volton.ee/kasutusjuhend" target="_blank" rel="noreferrer" className="text-gray-400 underline hover:text-gray-300 ml-1">
          Kasutusjuhend
        </a>
        <a href="https://volton.ee/kontakt" target="_blank" rel="noreferrer" className="text-gray-400 underline hover:text-gray-300 ml-1">
          Kontakt
        </a>
      </p>
    </footer>
  );
};

const App = () => {
  const location = useLocation();

  // Function to check if the current route is a SuperTokens login/register page
  const isAuthPage = () => {
    const authPaths = ["/login", "/auth", "/signup"];
    if (location.pathname.startsWith("/login")) {
      document.title = "Logi sisse | Volton";
    } else if (location.pathname.startsWith("/signup")) {
      document.title = "Registreeri | Volton";
    } else if  (location.pathname.startsWith("/auth")) {
      document.title = "Sisselogimine... | Volton";
    }

    return authPaths.some(path => location.pathname.startsWith(path));
  };
  return (
    isDemoMode ? (
      <div className="flex flex-col min-h-screen bg-gray-900">
      {isAuthPage() && <Header />}
        <Routes>
          <Route
            path="/dashboard"
            element={
              <>
                <Sidebar />
                <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                  <Dashboard />
                </div>
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <Sidebar />
                <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                  <Profile />
                </div>
              </>
            }
          />
          <Route
            path="/history"
            element={
              <>
                <Sidebar />
                <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                  <History />
                </div>
              </>
            }
          />
          <Route
            path="/feedback"
            element={
              <>
                <Sidebar />
                <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                  <Feedback />
                </div>
              </>
            }
          />
          <Route path="/" element={<Navigate to={`/dashboard${location.search}`} replace />} />
          <Route path="*" element={<Navigate to={`/dashboard${location.search}`} />} />
        </Routes>

        {/* Conditionally render the footer on the login/register pages */}
        {isAuthPage() && <Footer />}
      </div>
    ) : (
      <SuperTokensWrapper>
        <div className="flex flex-col min-h-screen bg-gray-900">
          <Routes>
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])}
            <Route
              path="/dashboard"
              element={
                <SessionAuth>
                  <Sidebar />
                  <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                    <Dashboard />
                  </div>
                </SessionAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <SessionAuth>
                  <Sidebar />
                  <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                    <Profile />
                  </div>
                </SessionAuth>
              }
            />
            <Route
              path="/history"
              element={
                <SessionAuth>
                  <Sidebar />
                  <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                    <History />
                  </div>
                </SessionAuth>
              }
            />
            <Route
              path="/feedback"
              element={
                <SessionAuth>
                  <Sidebar />
                  <div className="flex-grow mt-4 mr-4 ml-4 md:ml-20 mb-16 md:mb-6">
                    <Feedback />
                  </div>
                </SessionAuth>
              }
            />
            <Route path="/" element={<Navigate to={`/dashboard${location.search}`} replace />} />
            <Route path="*" element={<Navigate to={`/dashboard${location.search}`} />} />
          </Routes>
        </div>
      </SuperTokensWrapper>
    )
  );
};

export default App;