import React, { useState, useEffect, useCallback } from 'react';
import { BarChart2, ChevronLeft, ChevronRight, ShoppingCart } from 'lucide-react';
import { format, subDays, subMonths, subYears, startOfDay, startOfMonth, startOfYear, endOfDay, endOfMonth, endOfYear, min } from 'date-fns';
import { authenticatedFetch } from '../api';
import EnergyChart from './EnergyChart';
const isDemoMode = process.env.REACT_APP_DEMO === 'true';

const History = () => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [viewMode, setViewMode] = useState('day');
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated] = useState(new Date());

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let startDateParam, endDateParam;
      const currentDate = new Date();
      if (viewMode === 'hour') {
        startDateParam = startOfDay(startDate);
        endDateParam = min([endOfDay(startDate), endOfDay(currentDate)]);
      } else if (viewMode === 'day') {
        startDateParam = startOfMonth(startDate);
        endDateParam = min([endOfMonth(startDate), endOfMonth(currentDate)]);
      } else if (viewMode === 'year') {
        startDateParam = startOfYear(startDate);
        endDateParam = min([endOfYear(startDate), endOfYear(currentDate)]);
      }

      const response = await authenticatedFetch(`/devices/${selectedDevice}/hourly_power_usage?start_time=${startDateParam.toISOString()}&end_time=${endDateParam.toISOString()}`);
      if (!response.ok) throw new Error('Failed to fetch power usage data');
      const powerUsageData = await response.json();

      const priceResponse = await authenticatedFetch(`/prices/ee?start_time=${startDateParam.toISOString()}&end_time=${endDateParam.toISOString()}`);
      if (!priceResponse.ok) throw new Error('Failed to fetch price data');
      const priceData = await priceResponse.json();

      const aggregatedData = aggregateData(powerUsageData, priceData, viewMode);
      setData(aggregatedData);
    } catch (err) {
      setError('Error fetching data: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [selectedDevice, viewMode, startDate]);

  const aggregateData = (powerUsageData, priceData, mode) => {
    const aggregated = {};
    powerUsageData.forEach(usage => {
      const date = new Date(usage.timestamp.slice(0, -1));
      let key;
      if (mode === 'hour') {
        key = format(date, 'yyyy-MM-dd HH:00');
      } else if (mode === 'day') {
        key = format(date, 'yyyy-MM-dd');
      } else {
        key = format(date, 'yyyy-MM');
      }

      if (!aggregated[key]) {
        aggregated[key] = { energy_usage: 0, cost: 0, timestamp: key };
      }
      const price = priceData.find(p => p.timestamp.slice(0, -1) === usage.timestamp.slice(0, -1))?.price || 0;
      aggregated[key].energy_usage += Math.max(0, usage.energy_usage);
      aggregated[key].cost += (Math.max(0, usage.energy_usage) * price) / 100;
    });

    return Object.values(aggregated).sort((a, b) => new Date(a.timestamp.slice(0, -1)) - new Date(b.timestamp.slice(0, -1)));
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    if (selectedDevice) {
      fetchData();
    }
  }, [selectedDevice, viewMode, startDate, fetchData]);

  const fetchDevices = async () => {
    try {
      const response = await authenticatedFetch('/devices');
      if (response.ok) {
        const devicesData = await response.json();
        setDevices(devicesData.devices);
        if (devicesData.devices.length > 0) {
          setSelectedDevice(devicesData.devices[0].id);
        }
      } else {
        throw new Error('Failed to fetch devices');
      }
    } catch (error) {
      setError('Error fetching devices: ' + error.message);
    }
  };

  useEffect(() => {
    document.title = "Tarbimisjalugu | Volton"; // Muudab lehe title
  }, []);

  const handlePrevious = () => {
    if (viewMode === 'hour') setStartDate(subDays(startDate, 1));
    else if (viewMode === 'day') setStartDate(subMonths(startDate, 1));
    else if (viewMode === 'year') setStartDate(subYears(startDate, 1)); // Parandatud loogika
  };

  const handleNext = () => {
    const newDate = viewMode === 'hour' ? subDays(startDate, -1) :
                    viewMode === 'day' ? subMonths(startDate, -1) :
                    subYears(startDate, -1); // Parandatud loogika
    if (newDate <= new Date()) {
      setStartDate(newDate);
    }
  };

  return (
    <div className="bg-gray-900 background-grid text-gray-300 min-h-screen flex flex-col">
          <div className="flex justify-between items-center mb-2">
      <h1 className="text-xl lg:text-3xl font-bold text-white flex items-center text-gray-100 mb-0">
        <BarChart2 className="mr-3" />
        Energia tarbimisajalugu
      </h1>
      {isDemoMode && (
        <button
          onClick={() => window.open('https://volton.ee/hinnad', '_blank')}
          className="bg-gray-800 text-white mt-2 text-sm px-4 py-2 rounded-lg shadow-sm border border-amber-500 hover:bg-amber-600 transition-all duration-300 ease-in-out flex items-center"
        >
          <ShoppingCart size={16} className="mr-2" />
          Osta (59.99€)
        </button>
      ) }
    </div>
      <p className="text-sm lg:text-base text-gray-400 flex items-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-refresh-cw mr-2">
          <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"></path>
          <path d="M21 3v5h-5"></path>
          <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"></path>
          <path d="M8 16H3v5"></path>
        </svg>
        Viimane uuendus: {format(lastUpdated, 'dd.MM.yyyy HH:mm')}
      </p>
      <div className="bg-gray-800 p-4 rounded-lg mb-6 shadow-lg border border-gray-700 w-full">
        <div className="flex flex-nowrap items-center gap-3 mb-4">
          <select
            value={selectedDevice || ''}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="custom-select bg-gray-700 text-white rounded-lg p-2 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-all"
            style={{ width:'180px' }}
          >
            <option value="">Vali seade</option>
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.device_name}
              </option>
            ))}
          </select>

          <div className="flex rounded-lg overflow-hidden shadow-md w-full">
            {[
              ['hour', 'Tund'],
              ['day', 'Päev'],
              ['year', 'Kuu'], // Parandatud "Kuu" -> "Aasta"
            ].map(([mode, label]) => (
              <button
                key={mode}
                onClick={() => setViewMode(mode)}
                className={`px-4 py-2 w-full transition-colors ${
                  viewMode === mode
                    ? 'bg-gray-600 text-white font-semibold'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex items-center rounded-lg overflow-hidden mt-4 md:mt-0 justify-center">
          <button
            onClick={handlePrevious}
            className="p-2 bg-gray-700 hover:bg-gray-600 transition-colors rounded-l-lg"
          >
            <ChevronLeft />
          </button>
          <span className="px-4 py-2 bg-gray-700 font-medium" style={{ textAlign: 'center', width: '100%' }}>
            {format(
              startDate,
              viewMode === 'hour'
                ? 'dd.MM.yyyy'
                : viewMode === 'day'
                ? 'MMMM yyyy'
                : 'yyyy'
            )}
          </span>
          <button
            onClick={handleNext}
            className="p-2 bg-gray-700 hover:bg-gray-600 transition-colors rounded-r-lg"
            disabled={startDate >= new Date()}
          >
            <ChevronRight />
          </button>
        </div>
      </div>

      {loading && <LoadingSpinner />}
      {error && <ErrorMessage message={error} />}
      {!loading && !error && (
        <EnergyChart
          data={data}
          viewMode={viewMode}
          startDate={startDate}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      )}
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-8">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brand"></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="bg-red-500 text-white p-4 rounded-lg mb-4">
    <p className="font-bold">Viga:</p>
    <p>{message}</p>
  </div>
);

export default History;
