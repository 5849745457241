import React, { useState, useEffect } from 'react';
import { ComposedChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, ReferenceLine, ReferenceArea, Scatter, Rectangle } from 'recharts';
import { Activity, Info, FlipVertical, Waves, Clock, CalendarRange} from 'lucide-react';
import { format, parseISO, endOfDay, addDays, isWithinInterval } from 'date-fns';
import { authenticatedFetch } from '../api';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const weekdays = ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'];
    const date = parseISO(data.timestamp);
    const weekday = weekdays[date.getDay()];
    return (
      <div className="bg-gray-800/50 p-2 border border-gray-700 rounded-md shadow-md">
        <p className="text-white text-xs">{`${weekday} ${format(date, 'HH:mm')}-${format(date.setHours(date.getHours() + 1), 'HH:mm')}`}</p>
        <p className="text-brand text-xs">{`Hind: ${data.price.toFixed(2)} s/kWh`}</p>
        {data.powerUsage > 0 && (
          <>
            <p className="text-[#34D399] text-xs">{`Võimsus: ${data.powerUsage.toFixed(2)} kWh`}</p>
            <p className="text-[#FBBF24] text-xs">{`Kulu: ${data.cost.toFixed(2)} senti`}</p>
          </>
        )}
      </div>
    );
  }
  return null;
};
const DeviceSelector = ({ devices, selectedDevice, setSelectedDevice }) => {
  const automationModes = [
    { value: 'piirhind', label: 'Piirhind', icon: FlipVertical },
    { value: 'boiler', label: 'Boiler', icon: Waves },
    { value: 'consecutive', label: 'Järjest', icon: Clock },
    { value: 'manual', label: 'Graafik', icon: CalendarRange }
  ];

  const getDeviceAutomationModeIcon = (device) => {
    if (!device?.last_online) return Info;
    
    return automationModes.find((mode) => mode.value === device.automation_mode).icon;
  };

  return (
    <div className="inline-flex rounded-md shadow-sm max-w-fit" role="group">
      {devices.map((device, index) => {
        const Icon = getDeviceAutomationModeIcon(device); // Default to Info icon if no mode is found
        return (
          <button
            key={device.id}
            onClick={() => setSelectedDevice(device)}
            className={`
              px-2 py-2 text-xs font-medium
              ${selectedDevice?.id === device.id
                ? 'bg-gray-700 text-gray-300 ' // Selected device
                : 'bg-gray-700 text-gray-300 opacity-50' // Non-selected device
              }
              ${index === 0 ? 'rounded-l-md' : 'rounded-l-none'} // Leftmost button rounded
              ${index === devices.length - 1 ? 'rounded-r-md' : 'rounded-r-none'} // Rightmost button rounded
              ${index !== 0 ? '-ml-px' : ''} // Remove margin between buttons
              border border-gray-600
              focus:z-10 focus:ring-2 focus:ring-brand focus:outline-none
              transition-colors duration-200
            `}
          >
            <div className="flex items-center justify-center">
              <Icon size={16} className="mr-1" />
              <p className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[70px]">{device.device_name}</p>
            </div>
          </button>
        );
      })}
    </div>
  );
};

const EnergyPriceChart = ({ devices, updateTrigger }) => {
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);
  const [chartData, setChartData] = useState([]);
  const [onPeriods, setOnPeriods] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTimeIndex, setCurrentTimeIndex] = useState(null);
  const [currentTime, setCurrentTime] = useState(format(new Date(), 'HH:mm')); // lisatud currentTime

  // Lisame hooki, mis uuendab aega iga minuti tagant
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(format(now, 'HH:mm'));
      
      // Leiame ja uuendame currentTimeIndexi
      const index = chartData.findIndex(point => 
        isWithinInterval(now, {
          start: parseISO(point.timestamp),
          end: parseISO(chartData[chartData.indexOf(point) + 1]?.timestamp || point.timestamp)
        })
      );
      setCurrentTimeIndex(index);
    }, 60 * 1000); // iga 60 sekundi tagant

    // Puhastame intervalli, kui komponent eemaldatakse
    return () => clearInterval(interval);
  }, [chartData]);

  useEffect(() => {
    // Update selectedDevice when devices prop changes
    if (devices.length > 0) {
      const updatedDevice = devices.find(device => device.id === selectedDevice?.id);
      if (updatedDevice) {
        setSelectedDevice(updatedDevice);
      } else {
        setSelectedDevice(devices[0]);
      }
    }
  }, [selectedDevice, devices]);

  useEffect(() => {
    if (!selectedDevice) return;
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const now = new Date();
      const startDate = new Date(now - 3 * 60 * 60 * 1000);
      const endDate = endOfDay(addDays(new Date(), 2)); // Changed to 2 days ahead to get more future data

      try {
        const [priceResponse, powerUsageResponse] = await Promise.all([
          authenticatedFetch(`/prices/${selectedDevice.country}?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`),
          authenticatedFetch(`/devices/${selectedDevice.id}/hourly_power_usage?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`)
        ]);

        if (!priceResponse.ok || !powerUsageResponse.ok) throw new Error('Andmete pärimine ebaõnnestus');
        const [priceData, powerUsageData] = await Promise.all([priceResponse.json(), powerUsageResponse.json()]);

        let scheduleData;
        if (selectedDevice.schedule) {
          scheduleData = selectedDevice.schedule;
        } else {
          const scheduleResponse = await authenticatedFetch(`/devices/${selectedDevice.id}/schedule?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`);
          if (!scheduleResponse.ok) throw new Error('Graafiku pärimine ebaõnnestus');
          scheduleData = await scheduleResponse.json();
        }

        let currentState = false;
        const combinedData = priceData.map(pricePoint => {
          const schedulePoint = scheduleData.find(s => s.timestamp.slice(0, -1) === pricePoint.timestamp.slice(0, -1));
          const powerUsagePoint = powerUsageData.find(p => p.timestamp.slice(0, -1) === pricePoint.timestamp.slice(0, -1));

          if (schedulePoint) {
            currentState = schedulePoint.state;
          }
          const powerUsage = powerUsagePoint ? powerUsagePoint.energy_usage : 0;
          const cost = (pricePoint.price * powerUsage) / 100; // Convert to euros
          return {
            ...pricePoint,
            timestamp: pricePoint.timestamp.slice(0, -1),
            price: Number(pricePoint.price),
            state: currentState,
            powerUsage: powerUsage,
            cost: cost,
            deviceId: selectedDevice.id
          };
        });

        setChartData(combinedData);

        const periods = [];
        let start = null;
        for (let i = 0; i < scheduleData.length; i++) {
          if (scheduleData[i].state && start === null) {
            start = scheduleData[i].timestamp;
          } else if (!scheduleData[i].state && start !== null) {
            periods.push({ start, end: scheduleData[i].timestamp });
            start = null;
          }
        }

        if (start !== null) {
          const lastTimestamp = priceData[priceData.length - 1].timestamp.slice(0, -1);
          periods.push({ start, end: lastTimestamp });
        }

        if (periods.length > 0 && parseISO(periods[0].start) < now) {
          const estoniaTime = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Tallinn' }));
          const currentHour = format(estoniaTime, "yyyy-MM-dd'T'HH:00:00");
          periods[0].start = currentHour;
        }
        setOnPeriods(periods);

        const currentPricePoint = combinedData.find(point => new Date(point.timestamp) > new Date(now - 1 * 60 * 60 * 1000));
        if (currentPricePoint) setCurrentPrice(currentPricePoint.price);

        const index = combinedData.findIndex(point => 
          isWithinInterval(now, {
            start: parseISO(point.timestamp),
            end: parseISO(combinedData[combinedData.indexOf(point) + 1]?.timestamp || point.timestamp)
          })
        );
        setCurrentTimeIndex(index);
        setLoading(false);
      } catch (err) {
        setError('Andmete pärimine ebaõnnestus');
        setLoading(false);
        console.error(err);
      }
    };

    fetchData();
  }, [selectedDevice]);

  const formatXAxis = (timestamp) => {
    const parsedTime = parseISO(timestamp);
    const hour = parseInt(format(parsedTime, 'HH'));
    
    if (format(parsedTime, 'HH:mm') === '00:00') {
      return 'öö';
    }
    
    if (hour % 2 === 0) {
      return format(parsedTime, 'HH');
    }
    
    return '';
  };

  const renderWatermark = () => (
    <text
      x="50%"
      y="50%"
      fontFamily="sans-serif"
      fontSize="20"
      fill="#9ca3af"
      textAnchor="middle"
      dominantBaseline="middle"
      opacity="0.4"
    >
      <tspan x="50%" dy="-1em">Automaatrežiim peatatud</tspan>
    </text>
  );
  function CustomCursor(props) {
    const {
      stroke, pointerEvents, height, points, className, width
    } = props;
  
    const { x, y } = points[0];
    const gridWidth = width / chartData.length; 

    return (
      <>
        <Rectangle
          x={x}
          y={y}
          fillOpacity={0.1}
          fill={stroke}
          pointerEvents={pointerEvents}
          width={gridWidth}
          height={height}
          className={className}
        />
      </>
    );
  }

  if (loading) return <div className="text-center py-4">Laen...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  
  if (!selectedDevice?.last_online) {
    return (
      <div className="bg-gray-800 p-4 rounded-2xl shadow-lg border border-gray-700">
       <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <h2 className="text-base font-semibold text-white mb-2 flex">
          <Activity size={18} className="mr-2 text-brand mt-1" />
          Börsihind ja seadme graafik
        </h2>
        <div className="flex w-auto md:w-auto justify-end">
        <DeviceSelector 
            devices={devices} 
            selectedDevice={selectedDevice} 
            setSelectedDevice={setSelectedDevice} 
          />
        </div>
      </div>
        <div className="h-[300px] flex items-center justify-center">
          <div className="text-center">
            <Info size={32} className="text-gray-400 mx-auto mb-4" />
            <p className="text-gray-400 text-lg font-semibold">
              Seade pole veel ühendatud.
            </p>
          </div>
        </div>
      </div>
    );
  }
  
  const maxPrice = selectedDevice?.settings?.piirhind?.max_price;
  return (
    <div className="bg-gray-800 p-4 rounded-2xl shadow-lg border border-gray-700">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <h2 className="text-base font-semibold text-white mb-2 flex">
          <Activity size={18} className="mr-2 text-brand mt-1" />
          Börsihind ja seadme graafik
        </h2>
        <div className="flex w-auto md:w-auto justify-end">
        <DeviceSelector 
            devices={devices} 
            selectedDevice={selectedDevice} 
            setSelectedDevice={setSelectedDevice} 
          />
        </div>
      </div>
      
      <div className="overflow-x-auto md:overflow-x-visible">
        <div className="h-[300px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={chartData} margin={{ top: 0, right: 10, left: 0, bottom: 5 }}>
              <CartesianGrid 
                vertical={true}
                horizontal={true}
                stroke="#9ca3af"
                strokeOpacity={0.15}
                strokeWidth={1.3}
              />
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={formatXAxis}
                tick={{ fontSize: '0.75rem'}}
                stroke="#888"
                axisLine={true}
                tickLine={true}
                interval={0}
                textAnchor="middle"
                height={50}
              />
              <YAxis 
                yAxisId="left"
                stroke="#888"
                axisLine={false}
                tickLine={true}
                orientation="right"
                width={32}
                tickFormatter={(value) => `${value} s`}
                tick={{ fontSize: '0.75rem' }}
                padding={{ right: 0, left: 0 }} 
              />
              <YAxis 
                yAxisId="right"
                orientation="right"
                stroke="#888"
                axisLine={false}
                tickLine={false}
                hide={true}
                domain={[0, 'dataMax + 0.5']}
              />
              <YAxis 
                yAxisId="right2"
                orientation="right"
                stroke="#888"
                axisLine={false}
                tickLine={false}
                hide={true}
                domain={[0, 'dataMax + 0.5']}
              />
              <Tooltip 
                content={<CustomTooltip />} 
                isAnimationActive={false}
                cursor={<CustomCursor />}
              />
            
              {selectedDevice?.automation_enabled ? (
                <>
                  {maxPrice !== undefined && selectedDevice?.automation_mode === 'piirhind' && (
                    <ReferenceLine 
                      y={maxPrice} 
                      stroke="#9ca3af" 
                      strokeDasharray="3 3" 
                      yAxisId="left"
                      strokeWidth={1.5}
                      label={{ 
                        value: `Piirhind: ${maxPrice}s/kWh`, 
                        position: 'insideBottomLeft', 
                        fill: '#9ca3af',
                        fontSize: '0.75rem'
                      }} 
                    />
                  )}
                  {selectedDevice?.automation_mode === 'boiler' && selectedDevice?.settings?.boiler?.set_time?.map((hour) => (
                    chartData.map((point) => (
                      (parseISO(point.timestamp).getHours() === hour) && (<ReferenceLine 
                        x={point.timestamp}
                        strokeDasharray="1 1" 
                        stroke="#00d1ff"
                        strokeWidth={1}
                        yAxisId="left"
                        label={{ 
                          value: `Boiler on soe: ${hour}:00`, 
                          angle: 0, 
                          position: 'insideBottomRight', 
                          fill: '#ddd',
                          fontSize: '0.65rem'  
                        }}
                      />)
                    ))
                  ))}
                  {selectedDevice?.automation_mode === 'manual' && selectedDevice?.settings?.manual?.timers?.map((timer) => (
                    chartData.map((point) => (
                      (parseISO(point.timestamp).toTimeString().slice(0, 5) === timer.time) && (<ReferenceLine 
                        x={point.timestamp}
                        strokeDasharray="1 1" 
                        stroke="#00d1ff"
                        strokeWidth={1}
                        yAxisId="left"
                        label={{ 
                          value: `${(timer.action) === "on" ? "ON" : "OFF"} (${timer.time})`, 
                          angle: 0, 
                          position: 'insideBottomRight', 
                          fill: '#ddd',
                          fontSize: '0.65rem'  
                        }}
                      />)
                    ))
                  ))}
                  {onPeriods.map((period, index) => (
                    <ReferenceArea
                      key={index}
                      x1={period.start}
                      x2={period.end}
                      fill="#00d1ff"
                      fillOpacity={0.07}
                      yAxisId="left"
                    />
                  ))}
                </>
              ) : (
                renderWatermark()
              )}
              <Line 
                type="stepAfter" 
                dataKey="price" 
                stroke="#00D1FF" 
                strokeWidth={2.1}
                dot={false}
                yAxisId="left"
              />

              <Scatter
                dataKey="powerUsage"
                fill="#a3e635"
                yAxisId="right"
                opacity={0.5}
                shape={(props) => {
                  const { cx, cy, payload, yAxis, xAxis } = props;
                  const radius = payload.cost === 0 ? 0 : 1;
                  const yStart = yAxis.scale(0);
                  const gridWidth = xAxis.width / (chartData.length - 1);
                  const offset = gridWidth * 0.66;
            
                  return (
                    <g>
                      <line x1={cx + offset} y1={yStart} x2={cx + offset} y2={cy} stroke="#34D399" strokeWidth={2.1} opacity={0.9} />
                      <circle cx={cx + offset} cy={cy} r={radius} fill="#34D399" opacity={0.9} />
                    </g>
                  );
                }}
              />
              <Scatter
                dataKey="cost"
                fill="#fef08a"
                yAxisId="right2"
                shape={(props) => {
                  const { cx, cy, payload, yAxis, xAxis } = props;
                  const radius = payload.cost === 0 ? 0 : 1;
                  const yStart = yAxis.scale(0);
                  const gridWidth = xAxis.width / (chartData.length - 1);
                  const offset = gridWidth * 0.33;
            
                  return (
                    <g>
                      <line x1={cx + offset} y1={yStart} x2={cx + offset} y2={cy} stroke="#FBBF24" strokeWidth={2.1} opacity={0.9} />
                      <circle cx={cx + offset} cy={cy} r={radius} fill="#FBBF24" opacity={0.9} />
                    </g>
                  );
                }}
              />
              {currentTimeIndex !== null && (
                <ReferenceLine
                  x={chartData[currentTimeIndex].timestamp}
                  strokeDasharray="3 3" 
                  stroke="#ddd"
                  strokeWidth={1}
                  yAxisId="left"
                  label={{ 
                    value: `kell: ${currentTime}`,  // Uuendatud reaalajas kellaaeg
                    angle: 0, 
                    position: 'insideTopLeft', 
                    fill: '#ddd' 
                  }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="mt-1 text-sm text-gray-400 flex justify-between">
        <span>Praegune elektrihind: {currentPrice?.toFixed(2)} s/kWh</span>
      </div>
    </div>
  );
};

export default EnergyPriceChart;
