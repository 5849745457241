// src/api.js
import Session from "supertokens-auth-react/recipe/session";

const API_URL = process.env.REACT_APP_API_URL;

export const authenticatedFetch = async (url, options = {}) => {
  const isDemoMode = process.env.REACT_APP_DEMO === 'true';
  const defaultOptions = {
    ...options,
    headers: {
      ...options.headers,
      'X-Demo-Mode': isDemoMode ? 'true' : 'false',
    },
  };

  try {
    let response = await fetch(`${API_URL}${url}`, {
      ...defaultOptions,
      credentials: "include",
    });

    if (response.status === 401) {
      let session = await Session.doesSessionExist();
      if (!session) {
        throw new Error('Authentication failed');
      }
      // Retry the request
      response = await fetch(`${API_URL}${url}`, {
        ...defaultOptions,
        credentials: "include",
      });
    }

    return response;
  } catch (error) {
    console.error("Error in authenticatedFetch:", error);
    throw error;
  }
};