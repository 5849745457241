import React, { useState, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const EnergyChart = ({ data, viewMode, startDate, onPrevious, onNext }) => {
  const [hoveredData, setHoveredData] = useState(null);
  const [visibleBars, setVisibleBars] = useState({
    energy_usage: true,
    cost_in_cents: true,
    average_price: false
  });

  const formatXAxis = useCallback((timestamp) => {
    const date = new Date(timestamp);
    if (viewMode === 'hour') return format(date, 'HH:mm');
    if (viewMode === 'day') return format(date, 'dd MMM');
    return format(date, 'MMM yyyy');
  }, [viewMode]);

  const processedData = useMemo(() => {
    return data.map(item => ({
      ...item,
      average_price: item.energy_usage > 0 ? (item.cost / item.energy_usage) * 100 : 0, // Convert to s/kWh
      cost_in_cents: item.cost * 100 // Convert to cents
    }));
  }, [data]);

  const formatEnergy = (value) => {
    if (value < 1) {
      return `${(value * 1000).toFixed(0)} Wh`;
    }
    return `${value.toFixed(2)} kWh`;
  };

  const formatCost = (value) => {
    if (value.toFixed(0) === 1) {
      return `${value.toFixed(0)} sent`;
    }
    if (value < 100) {
      return `${value.toFixed(0)} senti`;
    }
    return `${(value / 100).toFixed(2)} €`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);
      const formattedDate = viewMode === 'hour' ? format(date, 'dd MMM yyyy HH:mm') :
                            viewMode === 'day' ? format(date, 'dd MMM yyyy') :
                            format(date, 'MMMM yyyy');
      
      return (
        <div className="bg-gray-800 p-3 rounded-lg shadow-lg border border-gray-700">
          <p className="font-semibold text-gray-200">{formattedDate}</p>
          {visibleBars.energy_usage && <p className="text-[#34D399]">Energia: {formatEnergy(payload[0].payload.energy_usage)}</p>}
          {visibleBars.cost_in_cents && <p className="text-[#FBBF24]">Kulu: {formatCost(payload[0].payload.cost_in_cents)}</p>}
          {visibleBars.average_price && <p className="text-blue-300">Keskmine hind: {payload[0].payload.average_price.toFixed(2)} s/kWh</p>}
        </div>
      );
    }
    return null;
  };

  const handleLegendClick = (dataKey) => {
    setVisibleBars(prev => ({
      ...prev,
      [dataKey]: !prev[dataKey]
    }));
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color, dataKey } = entry;
    const isVisible = visibleBars[dataKey];
    return (
      <span 
        style={{ color: isVisible ? color : 'gray', cursor: 'pointer' }}
        onClick={() => handleLegendClick(dataKey)}
      >
        {value}
      </span>
    );
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg  border border-gray-700">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-100">Energiakasutus ja -kulu</h2>
        <div className="flex items-center space-x-4">
          <span className="text-gray-300 font-medium">
            {format(startDate, viewMode === 'hour' ? 'dd MMM yyyy' : viewMode === 'day' ? 'MMMM yyyy' : 'yyyy')}
          </span>
        </div>
      </div>
      <div className="h-96">
      <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={processedData} 
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            onMouseMove={(e) => { if (e.activePayload) setHoveredData(e.activePayload[0].payload); }}
            onMouseLeave={() => setHoveredData(null)}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#4B5563" opacity={0.3} />
            <XAxis 
              dataKey="timestamp" 
              tickFormatter={formatXAxis} 
              stroke="#9CA3AF"
              tick={{ fill: '#D1D5DB', fontSize: 12 }}
              tickLine={{ stroke: '#6B7280' }}
              axisLine={{ stroke: '#6B7280' }}
            />
            <YAxis 
              yAxisId="left" 
              hide={true}
              stroke="#9CA3AF"
              tick={{ fill: '#D1D5DB', fontSize: 12 }}
              tickLine={{ stroke: '#6B7280' }}
              axisLine={{ stroke: '#6B7280' }}
              label={{ value: 'Energia (kWh)', angle: -90, position: 'insideLeft', fill: '#D1D5DB', fontSize: 14 }} 
              padding={{ top: 20, bottom: 0 }} 
              domain={[0, 'auto']}
              tickFormatter={(value) => value < 1 ? `${(value * 1000).toFixed(0)} W` : `${value.toFixed(1)} kW`}
            />
            <YAxis 
              yAxisId="right"
              hide={true}
              orientation="right"
              stroke="#9CA3AF"
              tick={{ fill: '#D1D5DB', fontSize: 12 }}
              tickLine={{ stroke: '#6B7280' }}
              axisLine={{ stroke: '#6B7280' }}
              label={{ value: 'Kulu (s)', angle: 90, position: 'insideRight', fill: '#D1D5DB', fontSize: 14 }}
              padding={{ top: 20, bottom: 0 }}
              domain={[0, 'auto']}
              tickFormatter={(value) => value < 100 ? `${value.toFixed(0)} s` : `${(value / 100).toFixed(2)} €`}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(107, 114, 128, 0.2)' }} />
            <Legend onClick={handleLegendClick} formatter={renderColorfulLegendText} wrapperStyle={{ paddingTop: '10px' }} />
           
            <Bar 
              dataKey="energy_usage" 
              name="Energia" 
              yAxisId="left" 
              fill="#34D399" 
              stroke="#10B981" 
              fillOpacity={0.8} 
              barSize={7}
              strokeWidth={0}
              hide={!visibleBars.energy_usage}
              animationDuration={300}
            />
            <Bar 
              dataKey="cost_in_cents" 
              name="Kulu" 
              yAxisId="right" 
              fill="#FBBF24" 
              stroke="#F59E0B" 
              fillOpacity={0.8}
              barSize={7}
              strokeWidth={0}
              hide={!visibleBars.cost_in_cents}
              animationDuration={300}
            />
            <Bar 
              dataKey="average_price" 
              name="Keskmine hind" 
              yAxisId="right" 
              fill="#60A5FA" 
              stroke="#3B82F6" 
              fillOpacity={0.8}
              barSize={7}
              strokeWidth={0}
              hide={!visibleBars.average_price}
              animationDuration={300}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {hoveredData && (
        <div className="mt-4 bg-gray-700 p-4 rounded-lg shadow">
          <h3 className="text-sm font-semibold text-gray-200 mb-2">
            {format(new Date(hoveredData.timestamp), 'dd MMM yyyy HH:mm')}
          </h3>
          <div className="grid grid-cols-[repeat(3,1fr)] gap-4">
              <div>
                <p className="text-[#34D399] text-sm font-medium">Energiakasutus</p>
                <p className="text-xl text-[#34D399]">{formatEnergy(hoveredData.energy_usage)}</p>
              </div>
              <div>
                <p className="text-[#FBBF24] text-sm font-medium">Kulu</p>
                <p className="text-xl text-[#FBBF24]">{formatCost(hoveredData.cost_in_cents)}</p>
              </div>
              <div>
                <p className="text-blue-400 text-sm font-medium">Keskmine hind</p>
                <p className="text-xl text-blue-400">{hoveredData.average_price.toFixed(2)} s/kWh</p>
              </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnergyChart;
