import React, { useState, useEffect } from 'react';
import { Plus, RefreshCw, ShoppingCart } from 'lucide-react';
import { format } from 'date-fns';
import { authenticatedFetch } from '../api';

import EnergyPriceChart from './EnergyPriceChart';
import DeviceStatus from './DeviceStatus';
import AddDeviceForm from './AddDeviceForm';
const isDemoMode = process.env.REACT_APP_DEMO === 'true';

const Dashboard = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [urlDeviceCode, setUrlDeviceCode] = useState(null);

  useEffect(() => {
    document.title = "Juhtpaneel | Volton"; // Muudab lehe title
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deviceCode = urlParams.get('c');
    if (deviceCode) {
      setShowAddDevice(true);
      setUrlDeviceCode(deviceCode);
    }
  }, []);

  const fetchDevices = async () => {
    setLoading(true);
    try {
      const response = await authenticatedFetch(`/devices`);
      if (!response.ok) throw new Error('Midagi läks valesti');
      const data = await response.json();
      setDevices(data.devices);
      setLastUpdated(new Date());
    } catch (err) {
      setError('Midagi läks valesti');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleAddDevice = (newDevice) => {
    setDevices([...devices, newDevice]);
    setShowAddDevice(false);
    setUpdateTrigger(prev => prev + 1);
  };

  const handleDeviceUpdate = (updatedDevice) => {
    const updatedDevices = devices.map(device => {
      console.log(device.id)
      console.log(updatedDevice)
      if (device.id === updatedDevice.id) {
        // Merge the existing device with the updated fields
        return {
          ...device,
          device_name: updatedDevice.device_name,
          automation_mode: updatedDevice.automation_mode,
          automation_enabled: updatedDevice.automation_enabled,
          settings: updatedDevice.settings,
          schedule: updatedDevice.schedule,
          status: updatedDevice.status !== undefined ? updatedDevice.status : device.status,
          online: updatedDevice.online !== undefined ? updatedDevice.online : device.online,
          power_consumption: updatedDevice.power_consumption !== undefined ? updatedDevice.power_consumption : device.power_consumption,
          last_online: updatedDevice.last_online || device.last_online,
        };
      }

      return device;
    })
    setDevices(updatedDevices);
    setUpdateTrigger(prev => prev + 1);
  };
  

  const handleDeviceDelete = (deletedDeviceId) => {
    setDevices(devices.filter(device => device.id !== deletedDeviceId));
    setUpdateTrigger(prev => prev + 1);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (devices.length === 0) return <EmptyDashboard onDeviceAdded={handleAddDevice} showAddDevice={showAddDevice} setShowAddDevice={setShowAddDevice} urlDeviceCode={urlDeviceCode} />;

  return (
    <div className="bg-gray-900 background-grid text-gray-300 min-h-screen flex flex-col">
      <main className="flex-0.5">
        <Header onAddDevice={() => setShowAddDevice(true)} onRefresh={fetchDevices} lastUpdated={lastUpdated} />
        <EnergyPriceChart devices={devices} updateTrigger={updateTrigger} />
        <DevicesOverview 
          devices={devices} 
          onDeviceUpdate={handleDeviceUpdate}
          onDeviceDelete={handleDeviceDelete}
        />
        {showAddDevice && !isDemoMode && (
          <Modal onClose={() => {
            setShowAddDevice(false);
            setUrlDeviceCode(null);
            // Remove the add_device parameter from the URL
            const url = new URL(window.location);
            url.searchParams.delete('add_device');
            window.history.replaceState({}, '', url);
          }}>
            <AddDeviceForm 
              onDeviceAdded={handleAddDevice} 
              onClose={() => setShowAddDevice(false)} 
              prefilledDeviceCode={urlDeviceCode}
            />
          </Modal>
        )}
      </main>
    </div>
  );
};
  


const Header = ({ onAddDevice, onRefresh, lastUpdated }) => (
  <header className="mb-4 lg:mb-8">
    <div className="flex justify-between items-center mb-2">
      <h1 className="text-xl lg:text-3xl font-bold text-white flex items-center text-gray-100 mb-0">
        <svg className="w-6 h-6 mr-2" viewBox="0 0 333 333" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M119.684 47.5H48L106.5 284.5L177 285L119.684 47.5Z" fill="white" stroke="white" />
          <path d="M172.5 165L212 47.5L298 47L245 128H293L177.5 285L218 165H172.5Z" fill="white" />
        </svg>
        Juhtpaneel
      </h1>
      {isDemoMode ? (
        <button
          onClick={() => window.open('https://volton.ee/hinnad', '_blank')}
          className="bg-gray-800 text-white mt-2 text-sm px-4 py-2 rounded-lg shadow-sm border border-amber-500 hover:bg-amber-600 transition-all duration-300 ease-in-out flex items-center"
        >
          <ShoppingCart size={16} className="mr-2" />
          Osta (59.99€)
        </button>
      ) : (
        <button
          onClick={onAddDevice}
          className="text-brand text-sm px-4 py-2 hover:text-brand/80 transition-colors duration-200 flex items-center"
        >
          <Plus size={16} className="mr-1" />
          Lisa seade
        </button>
      )}
    </div>
    <div className="flex items-center justify-start">
      <button
        onClick={onRefresh}
        className="text-gray-400 hover:text-brand transition-colors mr-2 duration-200"
        aria-label="Refresh data"
      >
        <RefreshCw size={16} />
      </button>
      <p className="text-sm lg:text-base text-gray-400 flex items-center">
        Viimane uuendus: {format(lastUpdated, 'dd.MM.yyyy HH:mm')}
      </p>
    </div>
  </header>
);

const DevicesOverview = ({ devices, onDeviceUpdate, onDeviceDelete }) => (
  <>
    <h2 className="text-lg font-bold mt-8 mb-2 text-white flex items-center">
      Seadmete ülevaade
      <span className="ml-4 text-xs font-normal text-gray-400">
        {devices.length} {devices.length === 1 ? "aktiivne seade" : "aktiivset seadet"}
      </span>
    </h2>
    <div className="grid grid-cols-1 gap-6">
      {devices.map(device => (
        <DeviceStatus
          key={device.id}
          device={device}
          onDeviceUpdate={onDeviceUpdate}
          onDeviceDelete={onDeviceDelete}
        />
      ))}
    </div>
  </>
);

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-brand"></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="text-red-500 text-center mt-10">{message}</div>
);

const EmptyDashboard = ({ onDeviceAdded, showAddDevice, setShowAddDevice, urlDeviceCode }) => {
  const handleAddDevice = (newDevice) => {
    onDeviceAdded(newDevice);
    setShowAddDevice(false);
  };

  return (
    <div className="bg-gray-900 background-grid text-gray-300 min-h-screen justify-center flex">
      <main className="flex-0.5">
        <div className="text-center mt-20">
          <h1 className="text-3xl font-bold text-white mb-4">Teie kontoga pole seotud ühtegi seadet.</h1>
          <p className="text-gray-400 mb-4">Palun lisage seade, et näha juhtpaneeli.</p>
          <button
            onClick={() => setShowAddDevice(true)}
            className="bg-brand text-gray-900 px-4 py-2 rounded-md hover:bg-brand/80 transition-colors duration-200"
          >
            <Plus size={20} className="inline mr-2" />
            Lisa uus seade
          </button>
        </div>
        {showAddDevice && (
          <Modal onClose={() => {
            setShowAddDevice(false);
            // Remove the 'c' parameter from the URL
            const url = new URL(window.location);
            url.searchParams.delete('c');
            window.history.replaceState({}, '', url);
          }}>
            <AddDeviceForm 
              onDeviceAdded={handleAddDevice} 
              onClose={() => setShowAddDevice(false)} 
              prefilledDeviceCode={urlDeviceCode}
            />
          </Modal>
        )}
      </main>
    </div>
  );
};

const Modal = ({ children, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="max-w-md w-full">
        {children}
      </div>
    </div>
  );

export default Dashboard;