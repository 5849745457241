import React, { useState } from 'react';
import { Plus, X, HousePlug, Barcode } from 'lucide-react';
import { authenticatedFetch } from '../api';

const AddDeviceForm = ({ onDeviceAdded, onClose, prefilledDeviceCode }) => {
  const [deviceName, setDeviceName] = useState('');
  const validateDeviceCode = (code) => {
    const hexRegex = /^[0-9A-Fa-f]{10}$/;
    return hexRegex.test(code);
  };
  const [deviceCode, setDeviceCode] = useState(validateDeviceCode(prefilledDeviceCode) ? prefilledDeviceCode : '');
  const [country, setCountry] = useState('ee');
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState('');
  const [isValidDeviceCode, setIsValidDeviceCode] = useState(validateDeviceCode(deviceCode));


  
  const handleDeviceCodeChange = (e) => {
    const code = e.target.value;
    setDeviceCode(code);
    setIsValidDeviceCode(validateDeviceCode(code));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsAdding(true);
    setError('');

    try {
      const response = await authenticatedFetch('/device_add_request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_name: deviceName,
          device_code: deviceCode,
          country: country,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.detail === "Device with this code already exists") {
          throw new Error('Selle koodiga seade on juba registreeritud!');
        } else {
          throw new Error('Seadme lisamine ebaõnnestus.');
        }
      }

      const result = await response.json();
      onDeviceAdded(result);
      setDeviceName('');
      setDeviceCode('');
      setCountry('ee');
      
      // Remove the 'add_device' parameter from the URL
      const url = new URL(window.location.href);
      url.searchParams.delete('c');
      window.history.replaceState({}, '', url.toString());
    } catch (err) {
      setError(err.message);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <div className="bg-gray-800 p-8 rounded-2xl shadow-lg border border-gray-700 m-6 relative">
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
      >
        <X size={24} />
      </button>
      <h2 className="text-2xl font-semibold mb-6 text-white flex items-center">
        <Plus size={28} className="mr-3 text-brand" />
        Lisa uus seade
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="deviceName" className="block text-sm font-medium text-gray-400 mb-2">
            Seadme nimi
          </label>
          <div className="relative">
            <HousePlug size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="text"
              id="deviceName"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              className="pl-10 pr-4 py-2 w-full rounded-md bg-gray-700 border border-gray-600 text-white focus:border-brand focus:ring focus:ring-brand focus:ring-opacity-50"
              placeholder="Sisesta seadme nimi"
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="deviceCode" className="block text-sm font-medium text-gray-400 mb-2">
            Seadme kood
          </label>
          <div className="relative">
            <Barcode size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="text"
              id="deviceCode"
              value={deviceCode}
              onChange={handleDeviceCodeChange}
              className={`pl-10 pr-4 py-2 w-full rounded-md bg-gray-700 text-white focus:ring focus:ring-opacity-50 ${
                deviceCode && !isValidDeviceCode
                  ? 'border border-red-500 focus:border-red-500 focus:ring-red-500'
                  : 'border border-gray-600 focus:border-brand focus:ring-brand'
              } ${prefilledDeviceCode && !!isValidDeviceCode ? 'opacity-50 cursor-not-allowed' : ''}`}
              placeholder="Sisesta seadme kood"
              required
              disabled={!!prefilledDeviceCode && !!isValidDeviceCode}
            />
          </div>
          {deviceCode && !isValidDeviceCode && (
            <p className="mt-2 text-red-500 text-sm">Palun sisestage kehtiv seadme kood</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-brand text-gray-900 py-2 px-4 rounded-md hover:bg-brand/80 transition-colors duration-200 disabled:opacity-50 flex items-center justify-center"
          disabled={isAdding || !isValidDeviceCode || !deviceName.trim()}
        >
          {isAdding ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Lisamine...
            </>
          ) : (
            <>
              <Plus size={20} className="mr-2" />
              Lisa seade
            </>
          )}
        </button>
      </form>
      {error && <p className="mt-4 text-red-500 text-sm">{error}</p>}
    </div>
  );
};

export default AddDeviceForm;